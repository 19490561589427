<!-- 展会动态 -->
<template>
  <div class="TradeShowTrends">
      <div class="nav">
          <H5Carousel/>
      </div>
      <div class="contents">
         <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" :key="index" @click="gopage(index)" :style="{background:item.background,color:item.color}">
                        {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
          <div class="content" v-if="navtitle == '新闻动态' || navtitle == 'News information'">
            <NewsInformation :tablelists="newslist"/>
            </div>
            <div class="content" v-else-if="navtitle == '精彩瞬间' || navtitle == 'Wonderful moment'">
            <wonderful />
            </div>
            <div class="content" v-else-if="navtitle == '展会排期' || navtitle == 'Exhibition scheduling'">
            <ExhibitionScheduling />
            </div>
            <div class="content" v-else-if="navtitle == '通知公告' || navtitle == 'NoticeNotice'">
            <NoticeNotice />
            </div>
            <div class="content" v-else-if="navtitle == '办展流程' || navtitle == 'Exhibition process'">
            <ExhibitionProcess />
            </div>
      </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
import NewsInformation from "../../components/h5TradeShowTrends/NewsInformation.vue";
import wonderful from "../../components/h5TradeShowTrends/wonderful.vue";
import ExhibitionScheduling from "../../components/h5TradeShowTrends/ExhibitionScheduling.vue";
import ExhibitionProcess from "../../components/h5TradeShowTrends/ExhibitionProcess.vue";
import NoticeNotice from "../../components/h5TradeShowTrends/NoticeNotice.vue";

export default {
    components:{
        H5Carousel,H5Bottom,NewsInformation,wonderful,ExhibitionScheduling,ExhibitionProcess,NoticeNotice
    },
    data() {
        return {
             nav:[
                {title:"新闻动态",background: "#197cc3",color: "#fff", titles:"News information"},
                {title:"精彩瞬间",background: "#fff",color: "#000", titles:"Wonderful moment"},
                {title:"展会排期",background: "#fff",color: "#000", titles:"Exhibition scheduling"},
                {title:"通知公告",background: "#fff",color: "#000", titles:"NoticeNotice"},
/*
                {title:"办展流程",background: "#fff",color: "#000", titles:"Exhibition process"},
*/
            ],
            activeNames: ['0'],
            navtitle:"" ,
            newslist:[],
            show:""
        }
    },
    created() {
        if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.navtitle = '新闻动态'
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff'
                        if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000'
                    }
                    return item
                })
                 if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }
        this.newsandtrends()
    },
    methods:{
        newsandtrends(){
        let data ={
            pageNum:"1",
            pageSize:"5"
        }
        this.$api.newsandtrends(data,res=>{
            if(res.data.code){
                let newslist = res.data.rows.map((item)=>{
                    item.date = item.createTime.substring(8,11)
                    item.year = item.createTime.substring(0,7)
                    item.newsContent = `${item.newsContent.substring(0,150)}...`
                    return item
                })
                this.newslist = newslist
                }
            })
        },
       gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                // console.log(item,index);
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff'
                }else{
                    item.background = '#fff'
                    item.color = '#000'
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'News information'
                }else{
                    this.navtitle = '新闻动态'
                }
               this.$router.replace({path:'/TradeShowTrends',query:{path:`新闻动态`}})
               this.activeNames = ["0"]
           }else if(val == 1){
                if(localStorage.getItem('locale') == 'en-US'){
                   console.log("1");
                    this.navtitle = 'Wonderful moment'
                }else{
                    console.log("2");
                    this.navtitle = '精彩瞬间'
                }
               this.$router.replace({path:'/TradeShowTrends',query:{path:`精彩瞬间`}})
               this.activeNames = ["1"]
           }else if(val == 2){
               if(localStorage.getItem('locale') == 'en-US'){
                   console.log("1");
                    this.navtitle = 'Exhibition scheduling'
                }else{
                    console.log("2");
                    this.navtitle = '展会排期'
                }
               this.$router.replace({path:'/TradeShowTrends',query:{path:`展会排期`}})
               this.activeNames = ["2"]
           }else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'NoticeNotice'
                }else{
                    this.navtitle = '通知公告'
                }
               this.$router.replace({path:'/TradeShowTrends',query:{path:`通知公告`}})
               this.activeNames = ["3"]
           }
    /*       else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                   console.log("1");
                    this.navtitle = 'Exhibition process'
                }else{
                    console.log("2");
                    this.navtitle = '办展流程'
                }
               this.$router.replace({path:'/TradeShowTrends',query:{path:`办展流程`}})
               this.activeNames = ["3"]
           }*/
        }
    }
}
</script>

<style scoped>
    .TradeShowTrends{
        width: 100%;
        height: 100%;
    }
    .contents{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
        border-bottom: 0.01rem solid #999;
        margin-bottom: 0.1rem;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .bot{
        margin-top: 0.5rem
    }
</style>
