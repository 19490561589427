<!-- 新闻动态 -->
<template>
  <div class="NewsInformation">
      <div class="contents">
            <div class="content" v-for="(item,index) in newslist" :key="index">
                    <img :src="item.introduceImg" alt="">
                <p class="contenttitle" style="margin-top: 0.2rem">
                    {{item.title}}</p>
                <p class="contenttext" >
                    {{item.introduce}}
                    </p><br>
                <input type="button" value="查看详情 >>" @click="lookparticulars(item.id)">
                <p class="contentright">
                    <span style="font-size: 0.18rem;font-family: Microsoft YaHei;font-weight: bold;color: #353535;line-height: 0.3rem;">
                        {{item.date}}</span>
                </p>    
            </div>
            <el-pagination
            @size-change="handleSizeChange"   
            @current-change="handleCurrentChange"
           :current-page="pageNum" 
           :page-size="pageSize"
            layout="total,prev, pager, next"
            :total="total" class="pagination">
       </el-pagination>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
             nav:[
                {title:"新闻动态",background: "#197cc3",color: "#fff"},
                {title:"精彩瞬间",background: "#fff",color: "#000"},
                {title:"展会排期",background: "#fff",color: "#000"},
                {title:"通知公告",background: "#fff",color: "#000"},
            ],     
            activeNames: ['0'], 
            navtitle:"新闻动态" ,
            newslist:[],
            total:null,
            pageNum:1,
            pageSize:5, 
        }
    },
    created() {
        this.newsandtrends()
    },
    methods:{
        newsandtrends(){
        let data ={
            pageNum:this.pageNum,
            pageSize:this.pageSize,
            newsType:'1'
        }
        this.$api.newsandtrends(data,res=>{
            if(res.data.code){
                let newslist = res.data.rows.map((item)=>{
                    // item.date = item.createTime.substring(8,11)
                    item.date = item.releaseTime.substring(0,11)
                    item.introduce = `${item.introduce.substring(0,46).slice(0,item.introduce.length-1)}...`
                    return item
                })
                this.newslist = newslist
                this.total=res.data.total;
                }
            }) 
        },
        handleSizeChange(pageSize) {
          this.pageSize = pageSize;
          this.newsandtrends()
        },

        handleCurrentChange(pageNum) {
          this.pageNum= pageNum;
          this.newsandtrends()
        },
        lookparticulars(id){
        this.$router.push({path:'lookparticulars',query:{id:id}})
        },
    }
}
</script>

<style scoped>
    .exhibition{
        width: 100%;
        height: 100%;
    }
    .contents{
        width: 100%;
    }
    .content{
        padding: 0.2rem;
        background-color: #FAFAFA;
        margin-bottom: 0.3rem;
        margin-top: 0.2rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        text-align: center
    }
    .content img{
        width: 3.2rem;
    }
    .content p{
        display: inline-block;
    }
    .contenttitle{
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0.3rem;
    }
    .contenttext{
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.3rem;
    }
    input{
        width: 1rem;
        height: 0.4rem;
        background: #197CC3;
        border-radius: 5px;
        color: #fff;
        border: 0;
    }
    .contentright{
        display: inline-block;
        text-align: center;
        margin-left: 1.2rem;
        margin-top: 0.2rem
    }
    .el-pagination{
        margin-left: 0.8rem;
    }
     ::v-deep .el-pager li{
        font-size: 20px;
    }
   ::v-deep .el-pagination__jump{
       font-size: 20px !important;
   }
   ::v-deep .el-pagination__total{
        font-size: 20px !important;
   }
   ::v-deep .el-input__inner{
        font-size: 20px;
   }
   ::v-deep .el-icon-arrow-left:before{
       font-size: 20px;
   }
   ::v-deep .el-icon-arrow-right:before{
       font-size: 20px;
   }
    .bot{
        margin-top: 0.5rem
    }
</style>