
<style>

</style>
 展会排期
<template>
  <div class="ExhibitionScheduling">
        <div class="content">
              <div class="contenttop">
                <input type="button" :value="this.lastYears"  :class="{yearClickEffect : clickYear == lastYears}" @click="yearClick(lastYears)"/>
                <input type="button" :value="this.nowYears"   :class="{yearClickEffect : clickYear == nowYears}" @click="yearClick(nowYears)"/>
              </div>
              <div class="contentts">
              <img src="../../assets/image/组36.png" alt="" style="width:0.4rem" @click="leftmove">
              <div class="contenttime" ref="dateScroll">
                  <div class="imgbox" >
                   <img :src="item.flag==true?item.imgs:imgUrls" v-for="(item,index) in imgUrl" :key="index" alt="" class="minimg" @click="getCurImg(item)">
                  <div class="timetext">
                      <span>1</span>
                      <span>2</span>
                      <span>3</span>
                      <span>4</span>
                      <span>5</span>
                      <span>6</span>
                      <span>7</span>
                      <span>8</span>
                      <span>9</span>
                      <span>10</span>
                      <span>11</span>
                      <span>12</span>
                  </div>
                  </div>
              </div>
              <div class="line">
                  </div>
               <img src="../../assets/image/组36拷贝.png" alt=""
                  style="width:0.4rem;" @click="rightmove">
                  </div>
              <div class="contentbottom" >
                      <div v-for="(item,index) in contentList" :key="index" class="" ref="content">
                      <div class="bottombox"  @click="showcover(index)" >
                        <div class="introduceImg" :style="{backgroundImage: 'url(' +item.introduceImg + ')'}"/>
                          <p class="bottomTitle">{{ $t(item.title) }}</p>
                          <p class="bottomTime">{{item.startTime}}-{{item.endTime}}</p>
                      </div>
                        <div class="bottombox1"  @click="showInfo(index)" style="text-align: center">
                        <p style="width: 3.32rem;height: 0.15rem;font-size: 0.15rem;font-family: Microsoft YaHei;font-weight: 400;color: #333333;line-height: 20px;margin-bottom: 20px">{{ $t(item.title) }}</p>
                        <p style="height: 0.1rem;font-size: 0.14rem;font-family: Microsoft YaHei;font-weight: 400;color: #999999;line-height: 0.14rem;margin-bottom: 0.3rem"> {{item.startTime}}-{{item.endTime}}</p>
                        <p style="height: 0.5rem;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;line-height: 0.2rem;word-wrap:break-word; word-break: break-all;margin-bottom:0.3rem">{{item.url}}</p>
                        <p style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;line-height: 0.3rem;">地址： {{ $t(item.address) }}</p>
                        <!-- <p style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;line-height: 0.3rem;">主办方： {{item.sponsor}}</p> -->
                        <p v-if="item.linkman" style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #666666;line-height: 0.3rem;">联系人： {{item.linkman}} {{item.phone}}</p>
                      </div>
                      </div>

                  </div>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="total,prev, pager, next"
                  :total="total" class="pagination">
              </el-pagination>
          </div>
      </div>
</template>
<script>
export default {
    data() {
        return {
          //数据集合
          contentList:[],
          //当前年
          nowYears:null,
          //去年
          lastYears:null,
          //选择的年
          clickYear:null,
          //选择的月
          clickMonth:null,
          total:0,
          pageNum:1,
          pageSize:5,
          xxx: 0,
            imgUrls:require('../../assets/image/矩形35拷贝 7.png'),
        imgUrl:[
            {id:1,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:2,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:3,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:4,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:5,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:6,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:7,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:8,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:9,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:10,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:11,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
            {id:12,imgs:require('../../assets/image/矩形35拷贝 8.png'),
            flag:true,
            },
        ],
        }
    },
  created(){

    this.getlist();
    this.$nextTick(()=>{
      this.getCurrentYear();
    })
  },
    methods: {
      //获取当前年和去年日期
      getCurrentYear() {
        let date = new Date();
        this.nowYears = date.getFullYear();
        this.clickYear = date.getFullYear();
        this.clickMonth = this.zeroize(date.getMonth()+1);
        let item =  {id: date.getMonth()+1,imgs:require('../../assets/image/矩形35拷贝 8.png'),
          flag:true,
        }
        this.getCurImg(item);
        this.clickmove(date.getMonth()+1,this.$refs.dateScroll);
        this.lastYears = this.nowYears-1
      },
      //点击年加载方法
      yearClick(name){
        this.resetPagination();
        this.clickYear = name;
        this.getlist();
      },
      //加载展会列表
      getlist(){
        let data ={
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          yearMonth:this.clickYear+"-"+this.clickMonth
        }
        this.$api.exhibition(data,res=>{
          if(res.data.code === 200){
            this.contentList = res.data.data
            this.total=res.data.total;
          }
        })
      },
      //大于10补零方法
      zeroize(obj) {
        if (obj < 10) {
          return '0' + obj
        } else {
          return obj
        }
      },
      //重置页面参数
      resetPagination(){
        this.pageNum = 1;
        this.pageSize = 5;
      },
    showcover: function(key){
      this.$refs.content[key].children.item(0).style.display = "none"
      this.$refs.content[key].children.item(1).style.display = "block"
    },
     showInfo: function(key){
       this.$refs.content[key].children.item(1).style.display = "none"
       this.$refs.content[key].children.item(0).style.display = "block"
    },
    inpwcolor: function(e){
       e.target.style.backgroundColor =  "#E4E4E4"
    },
      //根据条数显示
      handleSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.getlist()
      },
      //根据页数显示
      handleCurrentChange(pageNum) {
        this.pageNum= pageNum;
        this.getlist()
      },
    getCurImg: function(item){
      this.imgUrl.map((items)=>{
        if(item.id == items.id){
          this.resetPagination();
          this.clickMonth = this.zeroize(item.id);
          items.flag= false
          this.getlist()
        }else{
          items.flag=true
        }
        return items
      })
    },
    leftmove() {
              this.$refs.dateScroll.scrollLeft -= 50
            },
      rightmove() {
              this.$refs.dateScroll.scrollLeft += 50
            },
    clickmove(num,sorts){
        if (num < 5){
          sorts.scrollLeft = 0
          return
        }else if (num < 9){
          sorts.scrollLeft = 180
          return;
        }else {
          sorts.scrollLeft = 520
          return;
        }
      }
    }
}
</script>

<style scoped>
    .introduceImg{
      width: 100%;
      height: 60%;
      background-size:100%,100%;
      background-repeat:no-repeat;
    }
    .yearClickEffect{
      background-color : #197CC3;
      color : #fff;
    }
    .ExhibitionScheduling{
        width: 100%;
        height: 100%;
    }
     .content{
        padding: 0.2rem;
       /* height: 22.5rem;*/
        background-color: #FAFAFA;
        margin-top: 0.1rem;
    }
    .contenttop{
        text-align: center
    }
    .contenttop>input{
        width: 0.8rem;
        height: 0.4rem;
        margin-left: 0.2rem;
        border: 0
    }
    .contentts{
        position:relative;
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;
    }
    .contenttime{
        width: 3rem;
        align-items: center;
        position:relative;
        overflow: scroll;
    }
    .contenttime::-webkit-scrollbar{
        display: none;
    }
    .imgbox{
        width: 6rem;
        display:flex;
        justify-content: space-around;
        position: absolute;
        top: 0.15rem;
        left:0rem;
    }
    .contenttime>img{
        z-index: 1;
    }
    .minimg{
        width: 0.1rem;
        height: 0.1rem;
        z-index: 200;
    }
    .line{
        width: 2.55rem;
        height: 0.01rem;
        border-top: 0.01rem solid #CCCCCC;
        background-color:#CCCCCC;
        position: absolute;
        top:0.19rem;
        left: 0.4rem;
    }
    .timetext{
         width: 6rem;
         height: 0.2rem;
         position: absolute;
         display:flex;
         justify-content: space-around;
         top:-0.15rem;
         left: -0.01rem;
    }
    .timetext>span{
        display: inline-block;
        width: 0.1rem;
        text-align: center
    }
    .timetextyear{
        margin-top: 0.4rem;
        position: absolute;
        z-index: 100;
    }
    .timetextyear>p{
        position: relative;
        top: 0rem;
        right: 1rem;
    }
    .contentbottom{
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 0.5rem
    }
    .bottombox{
        width: 100%;
        height: 3rem;
        background: #FFFFFF;
        margin-top: 0.3rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.1);
    }
    .bottombox1{
      width: 100%;
      height: 3rem;
      background: #FFFFFF;
      display: none;
      margin-top: 0.3rem;
      box-shadow:2px 2px 5px rgba(0,0,0,0.1);
    }
    .bottombox>img{
        width: 100%;
    }
    .bottomTitle{
        width: 3rem;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.5rem;
        margin-left: 0.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .bottomTime{
        width: 1.5rem;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #999999;
        line-height: 0.2rem;
        margin-left: 0.2rem;
    }
    .el-pagination{
      margin-left: 0.8rem;
    }
    ::v-deep .el-pager li{
      font-size: 20px;
    }
    ::v-deep .el-pagination__jump{
      font-size: 20px !important;
    }
    ::v-deep .el-pagination__total{
      font-size: 20px !important;
    }
    ::v-deep .el-input__inner{
      font-size: 20px;
    }
    ::v-deep .el-icon-arrow-left:before{
      font-size: 20px;
    }
    ::v-deep .el-icon-arrow-right:before{
      font-size: 20px;
    }
</style>
